@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap);
@import url(https://fonts.googleapis.com/css?family=Numans);
html {
  height: -webkit-fill-available;overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: #fff;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.first {
  height: 800px;
  background-color: #ccc;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fallback */
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  touch-action:pan-y;
}

.homeDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.logo {
  width: 700px;
  top: 20%;
  position: relative;
}

.homeButton {
  width:300px;
}

.holderGame {
  width: 100%;
  height: 100%;
  position: relative;
}

.gameBar {
  position: absolute;
  background: transparent;
  z-index: 2;
  bottom: 0;
  right: 0;
  height: 40px;
}

.quitButton {
  width:110px;
  
}

.gameFrame{
  width: 100%;
  height: 100%;
}

.slogan {
  font-family: 'Bungee Shade', cursive;
  color:rgb(140, 161, 180);
}

.rotatingRecord {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.dblClickHeart {
  height: 80px;
  width: 80px;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 0;
}

.dblClickHeart.is-active {
  animation: popUpHeart 400ms linear;
  animation-fill-mode: forwards;
}

@keyframes popUpHeart {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.playButton {
  width: 70px;
}

.Screen {
  height: 800px;
  width: 450px;
  background-color: #000;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 20px;
  box-shadow: 2px 2px 120px rgba(255, 255, 255, 0.15);
}

#helpDiv img {
  position: absolute;
  z-index: 1;
  width:400px;
  left: 0; 
  right: 0; 
  top:20%;
  margin-left: auto; 
  margin-right: auto; 
}


@media (max-width: 650px) {
  .Screen {
    border-radius: unset;
    box-shadow: unset;
    width: 100vw;
    height: 100vh; /* Fallback */
    height: calc(var(--vh, 1vh) * 100);
  }

  .logo {
    width: 100%;
    top: 20%;
    position: relative;
  }
  
  .homeButton {
    width:60%;
  }
  
  #helpDiv img {
    width:250px;
  }
}

.fade-in {
  visibility: visible;
  opacity: 0.90;
  transition: opacity 2s linear;
}

.nada {
  visibility: hidden;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}



/** swiper **/

.swiper {
  /*width: 100%;
  height: 100%;*/
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}
.Video_Video__1aBIf {
  height: 100%;
  width: 100%;
  background-color: #666;
  color: #fff;
}

.Video_videoContainer__2vbLy {
  height: 100%;
  width: 100%;
  position: relative;
}

.Video_videoElement__3WE4P {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.Video_gridContainer__3lpRC {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.Video_grid__2-TrF {
  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 20px;
  padding: 20px;
  width: 100%;
  z-index: 1;
}

.Video_playButtonContainer__zlSMN {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Video_marquee__N4Kpd div div span {
  font-size: 0.9em;
}

.Video_shadow__1i6TW {
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
}

svg {
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
}
.Drawer_header__2nBHz {
    min-height: 64px;
    border-bottom: 1px solid #ddd;
  }
  
  .Drawer_overlay__1smDJ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .Drawer_overlayOpen__197_U {
    opacity: 1;
    z-index: 0;
    visibility: visible;
  }
  
  .Drawer_overlayHidden__1sUDo {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .Drawer_drawer__1yU3n {
    bottom: 0;
    flex: 1 0 auto;
    height: 27%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: absolute;
    overflow-y: auto;
    flex-direction: column;
    border-radius: 25px;
    background-color: #ffffff;
    width: 100px;
    flex-shrink: 0;
  
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  .Drawer_hidden__IGmbx {
    visibility: hidden;
    width: 240px;
    transform: translateX(240px);
    flex-shrink: 0;
    transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  
  
  .Drawer_animate__3_KkJ {
    visibility: visible;
    transform: none;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  
  .Drawer_drawerOpen__3AQ9p {
  }
  
  .Drawer_left__1wAdd {
    left: 0;
    right: auto;
  }
  
  .Drawer_right__2F2Z2 {
    right: 0;
    left: auto;
  }

/* Made with love by Mutiullah Samim*/

#containerLogin {
/*background-image: url('https://getwallpapers.com/wallpaper/full/8/2/2/575638.jpg');*/
background-image: url('/images/background.jpg');
background-size: cover;
background-repeat: no-repeat;
height: 100%;
width: 100%;
font-family: 'Numans', sans-serif;
font-size: 0.8rem;
}

#innerContainer {
    width: 80%;
    margin: 0 auto;
}

.loading {
    width: 50px;
    margin: auto;
}

  
#phoneIcon {
    padding:15px;
}

#header {
    margin-top: 50px;
}

#container_error {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    margin-top: 10px;
}

#error_message {
    color:#FFC312;
    font-weight: 800;
}

#success_message {
    color:#FFC312;
    font-weight: 800;
}

#fields {
    width: 80%;
    margin: 0 auto;
}

#msisdn {
    width: 60%;
    text-align: center;
}

#logindiv {
    width: 50%;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}

.container{
height: 100%;
align-content: center;
}

.card{
height: 370px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
background-color: rgba(0,0,0,0.5) !important;
}

.social_icon span{
font-size: 60px;
margin-left: 10px;
color: #FFC312;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
text-align: center;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

.input-group-prepend span{
width: 50px;
background-color: #FFC312;
color: black;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}

.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}

.login_btn{
color: black;
background-color: #FFC312;
width: 100px;
}

.login_btn:hover{
color: black;
background-color: white;
}

.links{
color: white;
}

.links a{
margin-left: 4px;
}
